import * as React from "react";

import Puzzle from "../../Puzzle";

import pamiatki from "../../images/pamiatki.jpg";
import map from '../../images/m4.png'
import audio from '../../../static/a4.mp3'

const Page = () => {
  return (
    <Puzzle
      title="Zagadka 4"
      author="Autorka: Agata Grzych"
      imageSrc={pamiatki}
      imageAlt="kolorowe talerze wiszące na białej ścianie"
      mapSrc={map}
      mapAlt="Punkt zaznaczony na mapie"
      audio={audio}
      language="pl"
      languageUrl={"/riddles/gP60VT1J7Z"}
    >
      <div>
        <p>
          Podstawową funkcją talerzy jest spożywanie z nich posiłków. I to jest
          sprawa dość oczywista. Ale talerze też mogą pełnić funkcje
          dekoracyjnych bądź pamiątkowych artefaktów.
        </p>
        <p>
          Ciężko określić kto, a przede wszystkim dlaczego wpadł na taki pomysł.
          Idea jednak się przyjęła. Talerze takie mogą być ręcznie malowane,
          pojawiają się na nich również nadruki z grafiką bądź z fotografiami.
          Swego czasu dość popularne były te z wizerunkiem papieża. Na portalach
          internetowych znajdziemy całkiem sporo talerzy jubileuszowych, w
          turystycznych miejscowościach można kupić talerze z
          najpopularniejszymi atrakcjami danego miejsca. Jest też cała kolekcja
          ceramiki naściennej rodem z Bolesławca albo Ćmielowa, w tej z kolei
          dominują brązowe albo niebieskie kwiatowe wzory. Wspomniane elementy
          zastawy stołowej o dekoracyjnym zastosowaniu można zobaczyć albo nabyć
          w najstarszym i największym antykwariacie we Wrocławiu Daes na placu
          Kościuszki, który przez wiele lat funkcjonował pod legendarną już
          nazwą Desa.
        </p>
        <p>
          Cepeliowa stylistyka zagościła na wiele lat w niektórych polskich
          mieszkaniach. Talerze ozdobne są przewrotne i nie całkiem
          niepraktyczne, w pewnym sensie wręcz perwersyjne. Wyobraźmy sobie, że
          jemy obiad na takim dekoracyjnym talerzu, a spod tradycyjnego
          polskiego dania wygląda Matka Boska Częstochowska. Same wartości
          estetyczne czy artystyczne również są kwestią sporną, chociaż obecnie
          wiele wzorów wraca do łask.{" "}
        </p>
        <p>
          Najwcześniejsze wspomnienia związane z moją babcią, która od zawsze
          mieszkała na KDMie, dotyczą dekoracyjnych talerzy. Cała kolekcja
          wisiała na ścianie w kuchni. Talerze w niebieskie kwiaty, brązowe,
          zegar zrobiony z talerza oraz ten z malaturą Matki Boskiej,
          umieszczony w salonie nad zdjęciami wnucząt. Odkąd pamiętam budziły
          moją fascynację, choć dość długo nie potrafiłam odnaleźć sensu w tym
          żeby wieszać je na ścianie czy też ustawiać pionowo na regale. Może
          nie są tak wieloznaczne jak obrazy, bardziej dostępne?{" "}
        </p>
        <p>
          „Pamiątki z KDMu" to seria talerzy, które są śladami historii
          Kościuszkowskiej Dzielnicy Mieszkaniowej, tyle że bardzo osobistymi. Z
          wnętrza, ze środka. Jest to praca prezentująca doświadczenie
          mieszkania na tym obszarze przez kilka miesięcy, z perspektywą
          niedługiego przeprowadzenia się w inne. Efekt przebywania w mieszkaniu
          babci, w którym na kilka miesięcy po jej śmierci niewiele się
          zmieniło, z drugiej strony konfrontowania się z miejscami, które
          kilkanaście lat temu wyglądały zupełnie inaczej.{" "}
        </p>
      </div>
    </Puzzle>
  );
};

export default Page;
